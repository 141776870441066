import React, { useState, useRef, useEffect } from "react";
import "../App.scss";
import { OctaveOptions } from "../models/customTypes";

type PianoPositionProps = {
  recording: boolean;
  userPianoHeight: number;
  chordsEnabled: boolean;
  setUserPianoHeight: React.Dispatch<React.SetStateAction<number>>;
  octaves: OctaveOptions;
};

const PianoPositionControl: React.FC<PianoPositionProps> = ({
  recording,
  userPianoHeight,
  setUserPianoHeight,
  chordsEnabled,
}) => {
  const [intervalId, setIntervalId] = useState<number | null>(null);
  const isMounted = useRef(true); // Ensure interval doesn't try to update unmounted component

  function movePianoUp() {
    if (recording) return;

    setUserPianoHeight((prevHeight) => {
      if (prevHeight + 25 > -25) {
        return -15;
      }

      return prevHeight + 25;
    });
  }

  function movePianoDown() {
    if (recording) return;

    setUserPianoHeight((prevHeight) => {
      if (chordsEnabled && prevHeight < -450) {
        return prevHeight;
      } else if (prevHeight < -575) {
        return prevHeight;
      }

      return prevHeight - 25;
    });
  }

  function handleMouseDownUp() {
    if (isMounted.current) {
      movePianoUp();
      const id = window.setInterval(movePianoUp, 100); // Adjust the interval as per your requirements
      setIntervalId(id);
    }
  }

  function handleMouseDownDown() {
    if (isMounted.current) {
      movePianoDown();
      const id = window.setInterval(movePianoDown, 100); // Adjust the interval as per your requirements
      setIntervalId(id);
    }
  }

  function handleMouseUp() {
    if (intervalId !== null) {
      clearInterval(intervalId);
      setIntervalId(null);
    }
  }

  useEffect(() => {
    if (chordsEnabled && userPianoHeight < -535) setUserPianoHeight(-535);
  }, [chordsEnabled]);

  // Cleanup on unmount
  useEffect(() => {
    isMounted.current = true;
    return () => {
      handleMouseUp();
      isMounted.current = false;
    };
  }, []);

  return (
    <div>
      <label className="control-label">Position:</label>
      <button
        type="button"
        className="btn btn-light btn-extra-small"
        onMouseDown={handleMouseDownUp}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
      >
        Move Piano Up
      </button>
      <button
        className="btn btn-light btn-extra-small"
        onMouseDown={handleMouseDownDown}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
      >
        Move Piano Down
      </button>
    </div>
  );
};

export default PianoPositionControl;
