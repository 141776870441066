import React, { FC } from "react";

type AudioSourceDropdownProps = {
  audioDevices: MediaDeviceInfo[];
  selectedAudioDevice: string | null;
  onSelect: (deviceId: string) => void;
};

const AudioSourceDropdown: FC<AudioSourceDropdownProps> = ({
  audioDevices,
  selectedAudioDevice,
  onSelect,
}) => {
  return (
    <div>
      <label className="control-label">Audio Source: </label>
      <select
        className="btn btn-light btn-extra-small"
        value={selectedAudioDevice ?? ""}
        onChange={(e) => onSelect(e.target.value)}
      >
        {audioDevices.map((device, index) => (
          <option key={device.deviceId} value={device.deviceId}>
            {device.label || `Audio Source ${index + 1}`}
          </option>
        ))}
      </select>
    </div>
  );
};

export default AudioSourceDropdown;
